import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import text, { detail_text_css } from "./text";
import { paragraph } from "./text";
import { paragraphStyling } from "./text";
import button from "./button";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1064px;
    padding: 0 20px;
    margin: 0 auto ${({theme}) => theme.marginSingleSmall};
    @media ${({ theme }) => theme.sm} {
        padding: 0 40px;
    }
    @media ${({ theme }) => theme.md} {
        padding: 0 100px;
        margin: 0 auto ${({theme}) => theme.marginSingle};
    }
`;

const HeroWrapper = styled.div`
    position: relative;
    height: 100%;
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${({theme}) => theme.marginSingleSmall} auto 10px auto;
    max-width: 1400px;
    padding: 0 20px;
    @media ${({ theme }) => theme.sm} {
        padding: 0 40px;
        margin: ${({theme}) => theme.marginSingle} auto 10px auto;
        height: 377px;
    }
    @media ${({ theme }) => theme.md} {
        padding: 0 100px;
        height: 477px;
    }
`;

const HeroBackground = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
    border-radius: ${({theme}) => theme.borderRadiusBig};
`;

const Date = styled(text.paragraph_small)`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: right;
    opacity: 0.7;
    margin-bottom: 46px;
    font-size: 14px;
    @media ${({ theme }) => theme.sm} {
        padding: 0 40px;
    }
    @media ${({ theme }) => theme.md} {
        padding: 0 100px;
    }
`;

const Category = styled.div`
    ${detail_text_css};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.backgroundColorAccent};
    color: ${({theme}) => theme.textPrimary};
    z-index: 2;
    border-radius: ${({theme}) => theme.borderRadiusSmall} ${({theme}) => theme.borderRadiusSmall} 0 0;
`;

const Title = styled(text.heading_xxl)`
    color: ${({theme}) => theme.textPrimary};
    padding-bottom: 70px;
    border-bottom: 1px solid ${({theme}) => theme.lavender};
    text-align: center;
`;

const Excerpt = styled(text.paragraph_big)`
    margin: 0;
`;

const WhatsIncludedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 35px;
`;

const WhatsIncludedTitle = styled(paragraph)`
    margin-bottom: 20px;
`;

const WhatsIncludedList = styled.ul`
    list-style: none;
`;

const WhatsIncludedItem = styled.li`
    ${paragraphStyling}
    display: flex;
    margin-bottom: 20px;
    svg {
        margin-right: 10px;
        flex-shrink: 0;
        margin-top: 3px;
        width: 20px;
        @media ${({ theme }) => theme.sm} {
            width: 25px;
            margin-top: 5px;
        }
    }
    &:last-of-type {
        margin-bottom: 0;
    }
`;

const AuthorWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    background-color: ${({theme}) => theme.breeze};
    border-radius: ${({theme}) => theme.borderRadiusBig};
    align-items: center;
    margin: 50px 0 0;
    width: 100%;
    gap: 15px;
    @media ${({ theme }) => theme.sm} {
        flex-wrap: nowrap;
        gap: 20px;
    }
`;

const AuthorImage = styled(GatsbyImage)`
    width: 100%;
    max-width: 103px;
    height: 103px;
    aspect-ratio: 1/1;
    border-radius: ${({theme}) => theme.borderRadiusSmall};
`;

const AuthorInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media ${({ theme }) => theme.sm} {
        flex-direction: row;
        align-items: center;
    }
`;

const TextNameWrapper = styled.div`
    width: 100%;
    margin-right: 15px;
    @media ${({ theme }) => theme.sm} {
        width: auto;
    }
`;

const AuthorTitle = styled(text.detail_text_mini)`
    color: ${({theme}) => theme.purple};
    margin-bottom: 5px;
`;

const AuthorName = styled(text.detail_text)`
    color: ${({theme}) => theme.textPrimary};
`;

const Button = styled(button.btn_mini)`
    margin: 0;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    @media ${({ theme }) => theme.sm} {
        width: auto;
        margin: 0;
        margin-left: auto;
    }
`;

const Content = styled(text.wysiwyg)`
    margin-top: 50px;
`;

const TextBlock = styled(text.wysiwyg)`
    background-color: ${({theme}) => theme.breeze};
    padding: 30px 20px;
    border-radius: ${({theme}) => theme.borderRadiusBig};
    margin-top: 50px;
    @media ${({ theme }) => theme.sm} {
        padding: 34px 40px;
    }
`;


const S = {
    Container,
    HeroWrapper,
    HeroBackground,
    Date,
    Category,
    Title,
    Excerpt,
    WhatsIncludedWrapper,
    WhatsIncludedTitle,
    WhatsIncludedList,
    WhatsIncludedItem,
    AuthorWrapper,
    AuthorImage,
    AuthorInfo,
    TextNameWrapper,
    AuthorTitle,
    AuthorName,
    Button,
    Content,
    TextBlock,
};

export default S;