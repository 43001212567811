import * as React from "react";
import { ThemeProvider } from "styled-components";

import Seo from "@/components/_SEO_";
import Body from "@/components/core-blocks/Body";
import Footer from "@/components/core-blocks/Footer";
import Header from "@/components/core-blocks/Header";
import Main from "@/components/core-blocks/Main";
import Sidebar from "@/components/core-blocks/Sidebar";
import GlobalStyle from "@/styles/global";
import { lightTheme } from "@/styles/themes/lightTheme";
import { Lang, WPImage } from "@/src/types";
import { LanguageProvider } from "../contexts/LanguageContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import S from "../styles/news.styled";
import Flexible, { IComponent } from "@/components/imports/Flexible";

export type INewsTemplate = {
  pageContext: {
    uri: string;
    title: string;
    language: {
      code: Lang;
    };
    id: string;
    date: string;
    categories: {
      nodes: {
        name: string;
      }[];
    };

    news_info: {
      image: WPImage;
      excerpt: string;
      whatsIncludedTitle: string;
      whatsIncluded: {
        item: string;
      }[];
      author: {
        nameAndPosition: string;
        link: string;
        image: WPImage;
      };
      content: string;
      textBlock: string;
    };
    page_components: {
      components: ReadonlyArray<IComponent>;
    };
    seo: {
      title: string;
      focuskw: string;
      metaDesc: string;
      opengraphAuthor: string;
      opengraphTitle: string;
      opengraphDescription: string;
      opengraphImage: {
        sourceUrl: string;
      };
    };
  };
};

const NewsTemplate: React.FC<INewsTemplate> = ({
  pageContext: {
    seo,
    language,
    categories,
    title,
    id,
    news_info,
    date,
    page_components,
  },
}: INewsTemplate) => {
  const {
    image,
    excerpt,
    author,
    content,
    textBlock,
    whatsIncludedTitle,
    whatsIncluded,
  } = news_info;

  const imageData = image?.localFile?.childImageSharp?.gatsbyImageData
    ? getImage(image.localFile.childImageSharp.gatsbyImageData)
    : null;

  const authorImageData = author?.image?.localFile?.childImageSharp
    ?.gatsbyImageData
    ? getImage(author.image.localFile.childImageSharp.gatsbyImageData)
    : null;

  return (
    <ThemeProvider theme={lightTheme}>
      <LanguageProvider lang={language.code}>
        <GlobalStyle />
        <Sidebar title={title} />
        <Body>
          <Header />
          <Main>
            <Seo
              title={seo.title}
              ogTitle={seo.opengraphTitle}
              description={seo.metaDesc}
              ogDescription={seo.opengraphDescription}
              author={seo.opengraphAuthor}
              image={seo.opengraphImage?.sourceUrl}
            />
            <S.HeroWrapper>
              {imageData && (
                <S.HeroBackground
                  image={imageData}
                  alt={image.altText || ""}
                  style={{ width: "100%" }}
                />
              )}
              <S.Category>{categories.nodes[0].name}</S.Category>
            </S.HeroWrapper>
            {date && <S.Date>{date}</S.Date>}
            <S.Container>
              {title && <S.Title>{title}</S.Title>}
              {excerpt && <S.Excerpt>{excerpt}</S.Excerpt>}
              {whatsIncludedTitle && (
                <S.WhatsIncludedWrapper>
                  <S.WhatsIncludedTitle>
                    {whatsIncludedTitle}
                  </S.WhatsIncludedTitle>
                  <S.WhatsIncludedList>
                    {whatsIncluded.length > 0 &&
                      whatsIncluded.map(({ item }, index) => (
                        <S.WhatsIncludedItem key={index}>
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="12.5"
                              cy="12.5"
                              r="12.5"
                              fill="#7054E2"
                            />
                            <path
                              d="M9.375 9.88961L13.4159 12.0839V12.1094L9.375 14.2273V17.9688L17.1875 13.6583V10.5605L9.375 6.25V9.88961Z"
                              fill="white"
                            />
                          </svg>
                          {item}
                        </S.WhatsIncludedItem>
                      ))}
                  </S.WhatsIncludedList>
                </S.WhatsIncludedWrapper>
              )}
              {author && (
                <S.AuthorWrapper>
                  {authorImageData && (
                    <S.AuthorImage
                      image={authorImageData}
                      alt={author.image.altText || ""}
                      style={{ width: "100%" }}
                    />
                  )}
                  <S.AuthorInfo>
                    <S.TextNameWrapper>
                      <S.AuthorTitle>Den här artikeln skrevs av:</S.AuthorTitle>
                      <S.AuthorName>{author.nameAndPosition}</S.AuthorName>
                    </S.TextNameWrapper>
                    {author.link && (
                      <S.Button href={author.link} target="_blank">
                        Connecta på linkedIn
                      </S.Button>
                    )}
                  </S.AuthorInfo>
                </S.AuthorWrapper>
              )}
              {content && (
                <S.Content dangerouslySetInnerHTML={{ __html: content }} />
              )}
              {textBlock && (
                <S.TextBlock dangerouslySetInnerHTML={{ __html: textBlock }} />
              )}
            </S.Container>
            {page_components.components && (
              <Flexible components={page_components.components} />
            )}
          </Main>
          <Footer />
        </Body>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default NewsTemplate;
